:root {
  --blue: #0074b3;
  --red: #e30000;
  --yellow: #dbd400;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

.app {
  align-items: center;
  background-color: var(--blue);
  font-family: monospace;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  transition: .3s height;
  justify-content: space-evenly;
}

.grid {
  background-color: var(--blue);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  align-items: center;
  justify-items: center;
  width: 100vmin;
  box-sizing: border-box;
  height: 85.714vmin;
}

.space {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 9999px;
  border: 3px solid #000;
  transition: .3s background-color;
  /*dev*/
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: monospace;
  font-weight: bold;
}

.space-fill-1 {
  background-color: var(--red);
}

.message-bar-color-1 {
  color: var(--red)
}

.space-fill-2 {
  background-color: var(--yellow);
}

.message-bar-color-2 {
  color: var(--yellow)
}

.message-bar {
  font-weight: bold;
  color: #fff;
  background-color: #123456;
  padding: 1rem;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.75);
  z-index: 1;
}

.modal-content {
  background-color: #fff;
  border-radius: 2rem;
  max-width: 90%;
  max-height: 90%;
  padding: 30px;
  width: 600px;
  height: 300px;
  border: 4px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modal-content--winner {
  background-image: url("/images/balloons.gif")
}

.modal-title {
  font-size: 2rem;
  font-weight: bold;
}

.modal-button {
  font-family: inherit;
  color: #fff;
  border: none;
  background: #0074b3;
  font-weight: bold;
  border-radius: 10px;
  padding: 1rem 2rem;
  cursor: pointer;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}

.debug {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  background: #000000aa;
  color: #fff;
}